import AuthService from "../auth.service";

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
    ? { status: { loggedIn: true }, user }
    : { status: { loggedIn: false }, user: null };

// const initialState = { status: { loggedIn: false }, user: null };

export const auth = {
  namespaced: true,
  state: initialState,
  getters: {
    isAuthenticated(state) {
      return state.status;
    },
  },
  actions: {
    login({ commit }, user) {
      return AuthService.login(user).then(
        (data) => {
          if (data.access_token) {
            commit("loginSuccess", data);
            AuthService.ProfileDetail().then(
                (data) => {
                  commit("loginsetProfile", data);
                }
            )
            return Promise.resolve(data);
          } else {
            commit("loginFailure");
            return Promise.reject(data);
          }
        },
        (error) => {
          commit("loginFailure");
          return Promise.reject(error);
        }
      );
    },
    /*loginSSO({ commit }, user) {
      return AuthService.loginSso(user).then(
        (data) => {
          if (data.access_token) {
            commit("loginSuccess", data);
            return Promise.resolve(data);
          } else {
            commit("loginFailure");
            return Promise.reject(data);
          }
        },
        (error) => {
          commit("loginFailure");
          return Promise.reject(error);
        }
      );
    },*/
    forgot({ commit }, user) {
      return AuthService.forgot(user).then(
        (data) => {
          commit("registerSuccess", data);
          return Promise.resolve(data);
        },
        (error) => {
          commit("registerFailure");
          return Promise.reject(error);
        }
      );
    },
    logout({ commit }) {
      AuthService.logout();
      commit("token/setLogout", null, { root: true });
      commit("token/setAttribute", null, { root: true });
      // commit("profile/setLogout", null, { root: true });
      commit("logout");
    },
    register({ commit }, user) {
      return AuthService.register(user).then(
        (response) => {
          commit("registerSuccess");
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("registerFailure");
          return Promise.reject(error);
        }
      );
    },
    refreshToken({ commit }, accessToken) {
      commit("refreshToken", accessToken);
    },
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;

    },
    loginsetProfile(state, profile){
      state.profileUser = profile
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    registerSuccess(state) {
      state.status.loggedIn = false;
    },
    registerFailure(state) {
      state.status.loggedIn = false;
    },
    refreshToken(state, accessToken) {
      state.status.loggedIn = true;
      state.user = { ...state.user, accessToken: accessToken };
    },
  },
};

class TokenService {
  getLocalRefreshToken() {
    const user = JSON.parse(localStorage.getItem("user"));
    return user?.refresh_token;
  }

  getLocalAccessToken() {
    const user = JSON.parse(localStorage.getItem("user"));
    return user?.access_token;
  }

  updateLocalAccessToken(token) {
    console.log(token, 'token dari lc')
    // let user = JSON.parse(localStorage.getItem("user"));
    // user.access_token = token;
    localStorage.removeItem("user")
    localStorage.setItem("user", JSON.stringify(token));
  }

  getUser() {
    return JSON.parse(localStorage.getItem("user"));
  }

  setUser(user) {
    localStorage.setItem("user", JSON.stringify(user));
  }
  setProfile(user) {
    localStorage.setItem("profile", JSON.stringify(user));
  }

  setToken(data) {
    localStorage.setItem("token", data);
  }

  removeUser() {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
  }
}

export default new TokenService();

import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/Dashboard.vue"),
        },
        {
          path: "/builder",
          name: "builder",
          component: () => import("@/view/pages/Builder.vue"),
        },
        {
          path: "/cms",
          name: "cms",
          component: () => import("@/view/pages/Cms.vue"),
        },
        {
          path: "/maintenance",
          name: "maintenance",
          component: () => import("@/view/pages/Maintenance.vue"),
        },
        {
          path: "/logistik",
          name: "logistik",
          component: () => import("@/view/pages/Logistik.vue"),
        },
        {
          path: "/karocel",
          name: "karocel",
          component: () => import("@/view/pages/karocel.vue"),
        },
        {
          path: "/sitedata",
          name: "sitedata",
          component: () => import("@/view/pages/SiteData.vue"),
        },
        {
          path: "/sensorstat",
          name: "sensorstat",
          component: () => import("@/view/pages/SensorStat.vue"),
        },
        {
          path: "/ticketing",
          name: "ticketing",
          component: () => import("@/view/pages/Ticketing.vue"),
        },
        {
          path: "/viewnewtiket",
          name: "newtiket",
          component: () => import("@/view/pages/viewall/new.vue"),
        },
        {
          path: "/viewpendingtiket",
          name: "pendingtiket",
          component: () => import("@/view/pages/viewall/pending.vue"),
        },
        {
          path: "/viewsupporttiket",
          name: "supporttiket",
          component: () => import("@/view/pages/viewall/support.vue"),
        },
        {
          path: "/tickethistory",
          name: "tickethistory",
          component: () => import("@/components/maintenance/tickethistory.vue"),
        },
        {
          path: "/historymaintenance",
          name: "historymaintenance",
          component: () => import("@/components/maintenance/historymaintenance.vue"),
        },
        {
          path: "/historyall",
          name: "historyall",
          component: () => import("@/components/maintenance/historyall.vue"),
        },
        {
          path: "/det_site",
          name: "det_site",
          component: () => import("@/components/ticketing/Det_site.vue"),
        },
        {
          path: "/test",
          name: "test",
          component: () => import("@/view/pages/test.vue"),
        },
        {
          path: "/visit",
          name: "visit",
          component: () => import("@/view/pages/Visit.vue"),
        },
        {
          path: "/url",
          name: "url",
          component: () => import("@/view/pages/Url.vue"),
        },
        {
          path: "/pendingpart",
          name: "pendingpart",
          component: () => import("@/view/pages/Pending.vue"),
        },
        {
          path: "/profil",
          name: "profil",
          component: () => import("@/components/profil/Profil.vue"),
        },
      ],
    },
    {
      path: "/custom-error",
      name: "error",
      component: () => import("@/view/pages/error/Error.vue"),
      children: [
        {
          path: "error-1",
          name: "error-1",
          component: () => import("@/view/pages/error/Error-1.vue"),
        },
        {
          path: "error-2",
          name: "error-2",
          component: () => import("@/view/pages/error/Error-2.vue"),
        },
        {
          path: "error-3",
          name: "error-3",
          component: () => import("@/view/pages/error/Error-3.vue"),
        },
        {
          path: "error-4",
          name: "error-4",
          component: () => import("@/view/pages/error/Error-4.vue"),
        },
        {
          path: "error-5",
          name: "error-5",
          component: () => import("@/view/pages/error/Error-5.vue"),
        },
        {
          path: "error-6",
          name: "error-6",
          component: () => import("@/view/pages/error/Error-6.vue"),
        },
      ],
    },
    {
      path: "/",
      component: () => import("@/view/pages/auth/login_pages/Login-1"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/login_pages/Login-1"),
        },
        {
          name: "register",
          path: "/register",
          component: () => import("@/view/pages/auth/login_pages/Login-1"),
        },
      ],
    },
    {
      path: "/loginsso",
      name: "loginsso",
      component: () => import("@/view/pages/auth/login_pages/Login-sso"),
      meta: {
        guest: true,
      },
      props: true,
    },
    {
      path: "/forgot-password/verify/:token",
      name: "ForgotPassword",
      component: () => import("@/view/pages/auth/ForgotPassword"),
      meta: {
        guest: true,
      },
      props: true,
    },
    {
      path: "*",
      redirect: "/404",
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue"),
    },
  ],
});

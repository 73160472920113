import api from "./api.service";
// import axios from "axios";
import TokenService from "./token.service";

class AuthService {
  login({ email, password }) {
    return api
      .post("/auth/login", {
        email,
        password,
      })
      .then((response) => {
        if (response.data) {
          TokenService.setUser(response.data);

          api.get("/auth/user").then((res) => {
            TokenService.setProfile(res.data);
          })
        }

        return response.data;
      });
  }
  loginsso({ token }) {
    return api
        .post("/auth/generate-token-from-keycloak", {
          token,
        })
        .then((response) => {
          if (response.data) {
            TokenService.setUser(response.data);

            api.get("/auth/user").then((res) => {
              TokenService.setProfile(res.data);
            })
          }

          return response.data;
        });
  }
  ProfileDetail() {
    return api
        .get("/auth/user", )
        .then((response) => {
          return response.data;
        });
  }
  /*loginSso({ email, password }) {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    let urlencoded = new URLSearchParams();
    urlencoded.append("username", email);
    urlencoded.append("password", password);
    urlencoded.append("client_id", process.env.VUE_APP_SSO_KOMINFO_CLIENT_ID);
    urlencoded.append(
      "client_secret",
      process.env.VUE_APP_SSO_KOMINFO_CLIENT_SECRET
    );
    urlencoded.append("grant_type", "password");

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };
    return fetch(
      `${process.env.VUE_APP_SSO_KOMINFO_URL}/auth/realms/${process.env.VUE_APP_SSO_KOMINFO_REALM}/protocol/openid-connect/token`,
      requestOptions
    )
      .then((r) => r.json())
      .then((data) => {
        console.log(data);
        // console.log(response.data);
        if (data.access_token === undefined) {
          return data;
        }
        // now call our own backend
        const requestOptionsSidat = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + data.access_token,
          },
        };
        return fetch(
          `${process.env.VUE_APP_URL_LOCAL}/token/generate-token-from-sso`,
          requestOptionsSidat
        )
          .then((r) => r.json())
          .then((data) => {
            console.log("Data returned from generate-token-from-sso");
            console.log(data);
            if (data.access_token) {
              TokenService.setUser(data);
              TokenService.setToken(data.access_token);
            }

            return data;
          })
          .catch((reason) => {
            return reason;
          });
      })
      .catch((error) => {
        console.log("error", error);
        return error;
      });
  }*/

  logout() {
    TokenService.removeUser();
  }

  forgot({ email }) {
    return api.post("/forgot-password/generate-code", {
      email,
    });
  }

  register({ username, email, password }) {
    return api.post("/auth/signup", {
      username,
      email,
      password,
    });
  }
}

export default new AuthService();

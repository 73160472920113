import axiosInstance from "./api.service";
import TokenService from "./token.service";
import Axios from "axios";
const setup = (store) => {
    axiosInstance.interceptors.request.use(
        (config) => {
            const token = TokenService.getLocalAccessToken();
            if (token) {
                config.headers["Authorization"] = 'Bearer ' + token;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    axiosInstance.interceptors.response.use(
        (res) => {
            return res;
        },
        async (err) => {
            console.log(err);
            const originalConfig = err.config;

            if (originalConfig.url !== "/auth/login" && err.response) {
                // Access Token was expired
                if (err.response.status === 401 && !originalConfig._retry) {
                    originalConfig._retry = true;

                    try {
                        const refreshtokenlc = TokenService.getLocalRefreshToken();
                        const rs = await Axios.post(process.env.VUE_APP_URL_LOCAL + "/token/refresh", {},{
                            headers: {
                                'Authorization': `Bearer ${refreshtokenlc}`
                            }})
                        console.log(rs.data, 'refresh token data', refreshtokenlc)
                        const { accessToken } = rs.data;
                        console.log(rs.data, 'respon data refresh token')
                        store.dispatch('token/refresh', accessToken);
                        TokenService.updateLocalAccessToken(rs.data);

                        return axiosInstance(originalConfig);
                    } catch (_error) {
                        let accessToken = {
                            "access_token": "",
                            "refresh_token": ""
                        }
                        TokenService.updateLocalAccessToken(accessToken);
                        return Promise.reject(_error);
                    }
                }
            }

            return Promise.reject(err);
        }
    );
};

export default setup;